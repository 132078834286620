import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import BuyCard from "./buyCard";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Slider from "./caresoule";
import {
  SingleCarData,
  accpetRejectOffer,
  getPurposal,
  deleteCar,
} from "../../Redux/Card/actions";
import carMamaMarketPlace from "../../contracts/CarMammasMarketplace.json";
import CarMammaVehicleRegistration from "../../contracts/CarMammaVehicleRegistration.json";
import { signersWallet, addresses } from "../../walletConnect";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Spinner from "../../Components/spinner/spinner";

//style

import styles from "./style.module.scss";

const DetailCars = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showDefaultCategory, setShowDefaultCategory] = useState(false);
  const [platformFee, setPlatformFee] = useState();
  let id = window.location.search.split("?")[1];
  const carData = useSelector(
    (state) => state?.CardReducer?.singleCarData?.data?.adDetails
  );


  useEffect(() => {
    setPlatformFee(((parseInt(carData?.price) / 100) * 2.5) / 0.015)
  }, [carData]);


  const proposalData = useSelector((state) => state?.CardReducer?.proposalData);
  const userData = useSelector((state) => state.authReducer?.login);
  useEffect(() => {
    if (id) {
      dispatch(
        SingleCarData({
          id,
        })
      );
      if (carData?.userTag === "Buyer") {
        dispatch(
          getPurposal({
            id,
          })
        );
      }
    }
  }, [id]);

  const handleAccept = async (carData, id, proposal) => {
    setLoader(true);
    const carMammasMarketplace = new ethers.Contract(
      carMamaMarketPlace.address,
      carMamaMarketPlace.abi,
      signersWallet
    );
    const amount = carData?.BuyerOffers[0]?.amount;
    const price = ethers.utils.parseEther(amount.toString());
    const _tokenId = carData?.tokenId;

    const _voucherBuyer = {
      carId: _tokenId,
      buyerAddress: carData?.BuyerOffers[0]?.buyerAddress,
      nftAddress: CarMammaVehicleRegistration.address,
      priceOffered: price,
      timeStamp: parseInt(carData?.BuyerOffers[0]?.currentTime),
      offerSignature: carData?.BuyerOffers[0]?.signature,
    };

    console.log(_tokenId, _voucherBuyer);
    try {
      const tx = await (
        await carMammasMarketplace.acceptOffer(_tokenId, _voucherBuyer)
      ).wait();
      if (tx.events) {
        dispatch(
          accpetRejectOffer({
            offerId: id,
            isAccepted: true,
            setLoader: setLoader,
            proposal: proposal,
          })
        );
      }
    } catch (error) {
      toast.error(error.reason);
      setLoader(false);
      console.log("Error: ", error);
    }
  };

  const handleReject = (id, proposal) => {
    dispatch(
      accpetRejectOffer({
        offerId: id,
        isAccepted: false,
        proposal: proposal,
      })
    );
  };

  const handleDelete = async (data) => {
    setShowDefaultCategory(false);
    if (data?.userTag === "Buyer") {
      setLoader(true);
      dispatch(
        deleteCar({
          carId: data.id,
          setLoader: setLoader,
        })
      );
    } else {
      if (signersWallet !== undefined && addresses !== undefined) {
        setLoader(true);
        let carNumber = parseInt(data?.tokenId);
        const marketplace = new ethers.Contract(
          carMamaMarketPlace.address,
          carMamaMarketPlace.abi,
          signersWallet
        );
        try {
          let res = await (await marketplace.deListCar(carNumber)).wait();
          if (res.events) {
            dispatch(
              deleteCar({
                carId: data.id,
                setLoader: setLoader,
              })
            );
          }
        } catch (error) {
          setLoader(false);
          toast.error(error.reason);
        }
      } else {
        toast.error("Please connect with wallet first");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={`container ${styles.carDetails}`}>
        {loader ? (
          <Spinner />
        ) : (
          <div className="row">
            <div className={`col-12 my-5 text-center ${styles.head}`}>
              <h3>Car Details</h3>
            </div>
            <div className={`col-lg-8 my-5 ${styles.cardText}`}>
              <Card sx={{ borderRadius: 3 }}>
                <CardHeader
                  title={`${carData?.make} ${carData?.model}`}
                  subheader={carData?.registerIn ? carData?.registerIn : ""}
                  style={{ textAlign: "center" }}
                />
                <CardContent sx={{ padding: 0 }}>
                  <Slider img={carData?.CarAdImages} data={carData} />
                  <div className="row">
                    <div className={`col-lg-5 mx-4`}>
                      <p className="" style={{ fontWeight: "bold" }}>
                        {" "}
                        Contact Number : {carData?.contactNumber}
                      </p>
                      <p className="" style={{ fontWeight: "bold" }}>
                        Description : {carData?.description}
                      </p>
                      <p className="" style={{ fontWeight: "bold" }}>
                        Mileage : {carData?.mileage}
                      </p>
                      <p className="" style={{ fontWeight: "bold" }}>
                        Features :
                        {carData?.features?.map((item) => {
                          return <span> {item}, </span>;
                        })}
                      </p>
                    </div>
                    <div className={`col-lg-5 mx-4`}>
                      <p className="" style={{ fontWeight: "bold" }}>
                        Exterior Color : {carData?.exteriorColor}
                      </p>
                      <p className="" style={{ fontWeight: "bold" }}>
                        Car Varient : {carData?.verient}
                      </p>
                      <p className="" style={{ fontWeight: "bold" }}>
                        Price : {Number(carData?.price).toFixed(2)} USDT +{" "}
                        {Number(platformFee).toFixed(2)} ROSH
                      </p>
                      <br />
                    </div>
                    {carData?.userId === userData?.id && (
                      <div className="col-lg-6 mx-4">
                        <Button
                          onClick={() => {
                            setShowDefaultCategory(true);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="col-lg-4 my-5 float-end">
              <div className="row">
                <div className={`col-12 text-center`}>
                  <BuyCard
                    id={id}
                    userData={userData}
                    carData={carData}
                    proposalData={proposalData}
                  />
                </div>
                {loader ? (
                  <>
                    <Spinner />
                    <h1 className="text-center">
                      Blockchain is being called! Please wait.
                    </h1>
                  </>
                ) : (
                  <>
                    {carData?.userId === userData?.id && (
                      <>
                        {carData?.BuyerOffers?.length > 0 ? (
                          <div className={`col-12 text-center my-2`}>
                            <Card sx={{ borderRadius: 3 }}>
                              <CardHeader title={`Car Offer`} />
                              <CardContent sx={{ padding: 0 }}>
                                {carData?.BuyerOffers?.map((item, index) => {
                                  return (
                                    <div
                                      className={`col-lg-12 p-2 text-start mb-5 pt-lg-1 pt-sm-5 mx-4`}
                                    >
                                      <p className="mt-5">
                                        Price : {item?.amount}
                                      </p>
                                      <p>
                                        Description :{" "}
                                        {item?.description
                                          ? item?.description
                                          : "N/A"}
                                      </p>
                                      {item?.status === "rejected" ? (
                                        <span>Rejected</span>
                                      ) : (
                                        <>
                                          {item?.status === "accepted" ? (
                                            <span className={`float-end mx-5`}>
                                              Accepted
                                            </span>
                                          ) : (
                                            <>
                                              <span
                                                className={`float-end mx-lg-5 mx-sm-0 mb-3 ${styles.acceptButton}`}
                                                onClick={() =>
                                                  handleAccept(
                                                    carData,
                                                    item?.id,
                                                    false
                                                  )
                                                }
                                              >
                                                Accept
                                              </span>
                                              <span
                                                className={`float-start mb-3 ${styles.acceptButton}`}
                                                onClick={() =>
                                                  handleReject(item?.id, false)
                                                }
                                              >
                                                Reject
                                              </span>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </CardContent>
                            </Card>
                          </div>
                        ) : (
                          <div className={`col-12 text-center`}>
                            {carData?.userTag !== "Buyer" && (
                              <Card sx={{ borderRadius: 3 }}>
                                <CardHeader title={`Car Offer`} />
                                <CardContent sx={{ padding: 0 }}>
                                  No data found
                                </CardContent>
                              </Card>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefaultCategory}
        onHide={() => {
          setShowDefaultCategory(false);
        }}
      >
        <Modal.Header>
          <Modal.Title className="h5">Delete Car</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              setShowDefaultCategory(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Col md={12} className="mb-3">
            <p>Are you sure want to delete this car</p>
          </Col>
          <div className="col-lg-12">
            <div className="">
              <Button
                type="submit"
                onClick={() => {
                  handleDelete(carData);
                }}
                className={styles.button}
              >
                Delete
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setShowDefaultCategory(false);
                }}
                className={`float-end ${styles.button}`}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailCars;
